import React from "react"
import { Link } from "gatsby"
import { FormattedMessage } from 'react-intl'
import { Container } from "@components"
import { Content } from "./style"

const NotFound = () => {
  return (
    <Container>
      <Content>
        <p><FormattedMessage id="error404.not.found.p.1" /></p>
        <p>
        <FormattedMessage id="error404.not.found.back.to.1" />
        &nbsp;
        <Link to="/">
          <FormattedMessage id="error404.not.found.back.to.2" />
        </Link>
        </p>
      </Content>
    </Container>
  )
}

export default NotFound
