export { default as Breadcrumbs } from '@components/Breadcrumbs'
export { default as Container } from '@components/Container'
export { default as CookiesNotification } from '@components/CookiesNotification'
export { default as Footer } from '@components/Footer'
export { default as Header } from '@components/Header'
export { default as Hero } from '@components/Hero'
export { default as Impressum } from '@components/Impressum'
export { default as NotFound } from '@components/NotFound'
export { default as Page } from '@components/Page'
export { default as Privacy } from '@components/Privacy'
export { default as ResponsiveImage } from '@components/ResponsiveImage'
export { default as SEO } from '@components/SEO'
export { default as ShareButton } from '@components/ShareButton'

// Home components
export { default as HomeServices } from '@components/home/HomeServices'
export { default as LandingHero } from '@components/home/Hero'

// About components
export { default as About } from '@components/about/About'
export { default as Bullets } from '@components/about/Bullets'
export { default as Director } from '@components/about/Director'
export { default as Figures } from '@components/about/Figures'

// Services components
export { default as Contact } from '@components/services/Contact'
export { default as Construction } from '@components/services/Construction'
export { default as Education } from '@components/services/Education'
export { default as Optimization } from '@components/services/Optimization'
export { default as Service } from '@components/services/Service'
export { default as ServiceContact } from '@components/services/ServiceContact'
export { default as Services } from '@components/services/Services'
export { default as Testing } from '@components/services/Testing'

// Contacts components
export { default as Address } from '@components/contact/Address'
export { default as Contacts } from '@components/contact/Contacts'

// Projects components
export { default as Projects } from '@components/projekti/Projects'
