import React from 'react'
import { Container } from '@components'
import { FormattedMessage } from 'react-intl'
import { Content } from './style'

const Privacy = () => (
  <Container>
    <Content>
      <h2><FormattedMessage id="impressum.company.name" /></h2>
      <dl>
        <dt><FormattedMessage id="impressum.mb" />:</dt>
        <dd>4107063</dd>
        <br />
        <dt><FormattedMessage id="impressum.mbs" />:</dt>
        <dd>080870434</dd>
        <br />
        <dt><FormattedMessage id="impressum.oib" />:</dt>
        <dd>79517545745</dd>
        <br />
        <dt><FormattedMessage id="impressum.euid" />:</dt>
        <dd>HRSR.080870434</dd>
        <br />
        <dt><FormattedMessage id="impressum.account" /></dt>
        <dd>Raiffeisen banci Austria d.d.</dd>
        <br />
        <dt><FormattedMessage id="impressum.giro.account" />:</dt>
        <dd>HR792484008-1106684052 Raiffeisenbank Austria d.d.</dd>
        <br />
        <dt><FormattedMessage id="impressum.capital" /></dt>
        <dd><FormattedMessage id="impressum.capital.value" /></dd>
        <br />
        <dt><FormattedMessage id="impressum.tel" />:</dt>
        <dd>+38513496811</dd>
        <br />
        <dt><FormattedMessage id="impressum.mob" />:</dt>
        <dd>+38598218359</dd>
        <br />
        <dt><FormattedMessage id="impressum.email" />:</dt>
        <dd>info@fsb-zagreb.hr</dd>
        <br />
        <dt><FormattedMessage id="impressum.company.register" /></dt>
        <dd>Trgovački sud u Zagrebu</dd>
      </dl>
    </Content>
  </Container>
)

export default Privacy
