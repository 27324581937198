import React from 'react'
import { Container } from '@components'
import {
  Section,
} from './style'

const Address = () => (
  <Section>
    <Container small>
      <iframe title="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2781.0299667254976!2d15.8745538433502!3d45.8106582371243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765d1a06eb53a81%3A0xf767673f33bcec1!2sFSB%20d.o.o.!5e0!3m2!1sen!2shr!4v1584270846808!5m2!1sen!2shr" width="100%" height="450" frameborder="0" aria-hidden="false"></iframe>
    </Container>
  </Section>
)

export default Address
