import React from 'react'
import { Fade } from 'react-reveal'
import {
  ResponsiveImage,
} from '@components'
import {
  ImageContainer,
  ServiceContainer,
  TextContainer,
} from './style'

const Service = ({
  alt,
  alternative,
  children,
  img,
  to,
}) => (
  <ServiceContainer>
    <ImageContainer to={to}>
      <ResponsiveImage
        alt={alt}
        filename={img}
      />
    </ImageContainer>
    <TextContainer alternative={alternative} theme="theme">
      <Fade bottom distance="50px">
      {children}
      </Fade>
    </TextContainer>
  </ServiceContainer>
)

export default Service
