import styled from 'styled-components'

export const Section = styled.section`
  padding: 64px 0;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-around;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  h2 {
    font-weight: 500;
  }
`

export const ImageContainer = styled.div`
  flex: 1 1 200px;
  margin-right: 30px;

  @media (max-width: 800px) {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }

  span {
    margin-top: 8px;
    display: block;
    text-align: center;
  }
`

export const TextContainer = styled.div`
  flex: 3 3 500px;

  h2 {
    @media (max-width: 600px) {
      font-size: 22px;
    }
  }

  p {
    line-height: 1.5;

    @media (max-width: 600px) {
      font-size: 15px;
    }
  }
`