import styled from 'styled-components'

export const Image = styled.div`
  max-width: 500px;
  margin: 0 auto;
`

export const ColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 80px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`

export const Column = styled.div`
  flex: 1;
  padding: 0 50px;
  box-sizing: border-box;
  order: ${props => props.alternative ? '-1' : '1'};
  max-width: 500px;

  @media (max-width: 850px) {
    padding: 0 24px;
  }

  @media (max-width: 600px) {
    padding: 0;
  }

  h2 {
    font-size: 30px;
    font-weight: 500;
    margin: 24px 0;

    @media (max-width: 850px) {
      font-size: 26px;
    }

    @media (max-width: 600px) {
      font-size: 22px;
    }
  }

  p {
    line-height: 1.5;

    @media (max-width: 850px) {
      font-size: 15px;
    }
  }
`
