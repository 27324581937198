module.exports.germanPages = {
  '/404/': '/404/',
  '/about/': '/uber-uns/',
  '/contact/': '/kontakt/',
  '/impressum/': '/impressum/',
  '/': '/',
  '/privacy/': '/geheimhaltung/',
  '/projects/': '/projekte/',
  '/services/': '/dienstleistungen/',
  '/services/education/': '/dienstleistungen/schulung/',
  '/services/execution-of-fire-protection-works/': '/dienstleistungen/ausfuhrung-der-brandschutzarbeiten/',
  '/services/laboratory-tests/': '/dienstleistungen/laboruntersuchungen/',
  '/services/cost-optimization-of-fire-protection/': '/dienstleistungen/optimierung-der-brandschutzkosten/',
}