import React, { useCallback, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { CookiesNotification, Footer, Header, SEO } from '@components'
import { GlobalStyles } from './style'

const translations = {
  en: require(`@utils/lang/en.json`),
  de: require(`@utils/lang/de.json`),
}

const Page = ({ children, locale = 'en', meta }) => {
  const [trackedScroll, setTrackedScroll] = useState([])

  const scrollHandler = useCallback(() => {
    const scrollOffset = document.body.scrollTop || document.documentElement.scrollTop
    const windowHeight = window.innerHeight
    const documentHeight = document.body.scrollHeight || document.documentElement.scrollHeight
    const scrollPercentage = Math.round((scrollOffset + windowHeight) / documentHeight * 100);

    if (scrollPercentage >= 100 && trackedScroll.indexOf(100) === -1) {
      setTrackedScroll([...trackedScroll, 100])
      trackCustomEvent({ category: 'Scroll depth', action: meta.title, label: 100 })
    } else if (scrollPercentage >= 75  && trackedScroll.indexOf(75) === -1) {
      setTrackedScroll([...trackedScroll, 75])
      trackCustomEvent({ category: 'Scroll depth', action: meta.title, label: 75 })
    } else if (scrollPercentage >= 50  && trackedScroll.indexOf(50) === -1) {
      setTrackedScroll([...trackedScroll, 50])
      trackCustomEvent({ category: 'Scroll depth', action: meta.title, label: 50 })
    } else if (scrollPercentage >= 25  && trackedScroll.indexOf(25) === -1) {
      setTrackedScroll([...trackedScroll, 25])
      trackCustomEvent({ category: 'Scroll depth', action: meta.title, label: 25, nonInteraction: true })
    }

  }, [setTrackedScroll, trackedScroll, meta.title])

  useEffect(
    () => {
      window.addEventListener('scroll', scrollHandler)
      return () => {
        window.removeEventListener('scroll', scrollHandler)
      }
    }
  )

  return (
    <IntlProvider locale={locale} defaultLocale="en" messages={translations[locale]}>
      <SEO meta={meta} />
      <GlobalStyles />
      <Header />
      <main>
        {children}
      </main>
      <CookiesNotification locale={locale} />
      <Footer locale={locale} />
    </IntlProvider>
  )
}

export default Page;
