import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import Helmet from 'react-helmet'
import { Container } from '@components'
import { useSiteData } from '@utils/functions'
import ArrowIcon from '@assets/images/arrow.svg'
import { Content, MobileContent, Section, StyledLink } from './style'

const Breadcrumbs = ({ links }) => {
  const mobileBread = links.length > 1 ? links[links.length -2 ] : links[0]
  const siteData = useSiteData()

  const breads = links.map((l, i) => ({
    "@type": "ListItem",
    position: i + 1,
    name: l.name,
    item: `${siteData.siteUrl}${l.link}`,
  }))

  const StructuredObject = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": breads,
  }

  return (
    <Section>
      <Container>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(StructuredObject)}
          </script>
        </Helmet>
        <Content>
          {
            links.map((l, i) => (
              <Fragment key={l.link}>
                <StyledLink to={l.link}>
                  <FormattedMessage id={l.name} />
                </StyledLink>
                {
                  i + 1 < links.length &&
                  <i>-</i>
                }
              </Fragment>
            ))
          }
        </Content>
        <MobileContent>
          <StyledLink to={mobileBread.link}>
            <img src={ArrowIcon} alt="" height="20px" />
            <span>
              <FormattedMessage id={mobileBread.name} />
            </span>
          </StyledLink>
        </MobileContent>
      </Container>
    </Section>
  )
}

export default Breadcrumbs
