import styled from 'styled-components'

export const StyledButton = styled.div`
  background: #2867B2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  appearance: none;
  border: 0;
  height: 26px;
  width: 60px;
  padding: 0 10px;
  border-radius: 2px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  opacity: 0.95;

  &:hover {
    opacity: 1;
  }

  img {
    margin-right: 6px;
  }
`
