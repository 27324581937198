import React from 'react'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import { Container, Service } from '@components'
import { ServicesContainer } from './style'
import { buildLocalizedPath } from '@utils/functions'

const Services = ({ locale }) =>  (
  <ServicesContainer>
    <Container small>
      <Service
        alt="Izvođenje radova protupožarne zaštite - FSB d.o.o."
        img="zgrada.jpg"
        to={buildLocalizedPath('/services/execution-of-fire-protection-works/', locale)}
      >
        <h2><FormattedMessage id="services.service.1.title" /></h2>
        <p><FormattedMessage id="services.service.1.description" /></p>
        <Link to={buildLocalizedPath('/services/execution-of-fire-protection-works/', locale)}>
          <FormattedMessage id="More information" />
        </Link>
      </Service>
      <Service
        alternative
        alt="Optimizacija troškova protupožarne zaštite - FSB d.o.o."
        img="opti.jpg"
        to={buildLocalizedPath('/services/cost-optimization-of-fire-protection/', locale)}
      >
        <h2><FormattedMessage id="services.service.2.title" /></h2>
        <p><FormattedMessage id="services.service.2.description" /></p>
        <Link to={buildLocalizedPath('/services/cost-optimization-of-fire-protection/', locale)}>
          <FormattedMessage id="More information" />
        </Link>
      </Service>
      <Service
        alt="Edukacija - jamstvo izvrsnosti i sigurnosti - FSB d.o.o. protupožarna zaštita"
        img="edu.jpg"
        to={buildLocalizedPath('/services/education/', locale)}
      >
        <h2><FormattedMessage id="services.service.3.title" /></h2>
        <p><FormattedMessage id="services.service.3.description" /></p>
        <Link to={buildLocalizedPath('/services/education/', locale)}>
          <FormattedMessage id="More information" />
        </Link>
      </Service>
      <Service
        alternative
        alt="Laboratorijsko ispitivanje - FSB d.o.o. protupožarna zaštita"
        img="lab.jpg"
        to={buildLocalizedPath('/services/laboratory-tests/', locale)}
      >
        <h2><FormattedMessage id="services.service.4.title" /></h2>
        <p><FormattedMessage id="services.service.4.description" /></p>
        <Link to={buildLocalizedPath('/services/laboratory-tests/', locale)}>
          <FormattedMessage id="More information" />
        </Link>
      </Service>
    </Container>
  </ServicesContainer>
)

export default Services
