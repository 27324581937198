import styled from 'styled-components'

export const Section = styled.section`
  padding: 64px 0;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Text = styled.div`
  flex: 4;
  max-width: 700px;

  > p {
    font-size: 17px;
    line-height: 1.5;
  }

  > ul {
    font-size: 17px;
    line-height: 2;
  }
`
