import styled from 'styled-components'

export const Section = styled.section`
  padding: 64px 0;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const Text = styled.div`
  flex: 4;
  max-width: 700px;
  font-size: 17px;

  > p {
    font-size: 17px;
    line-height: 1.5;

    span {
      font-weight: 500;
    }
  }

  > h3 {
    margin-top: 36px;
  }
`

export const ImageContainer = styled.div`
  margin-left: 80px;
  flex: 3;

  @media (max-width: 800px) {
    order: -1;
    margin-left: 0;
    max-width: 600px;
    margin-bottom: 32px;
  }
`