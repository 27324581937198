import styled from 'styled-components'
import { darkGray, red } from '@styles/theme'
import { transition } from '@styles/utils'

export const C = styled.div`
  position: fixed;
  z-index: 15;
  left: 16px;
  bottom: 16px;
  width: calc(100% - 32px);
  background: #fff;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  padding: 12px 8px;

  @media (max-width: 600px) {
    flex-direction: column;
    font-size: 13px;
    justify-content: flex-start;
  }

  a {
    color: ${red};
  }

  p {
    flex: 3;
    margin: 0 48px 0 0;

    @media (max-width: 600px) {
      margin: 0;
    }
  }

  button {
    appearance: none;
    border: 0;
    text-transform: uppercase;
    color: #fff;
    background: ${red};
    text-decoration: none;
    padding: 12px 24px;
    letter-spacing: 0.5px;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    transition: ${transition('background')};

    @media (max-width: 600px) {
      margin-top: 16px;
    }

    &:hover {
      background: ${darkGray};
    }
  }
`