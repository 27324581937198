import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'react-intl'
import { Container, ResponsiveImage } from '@components'
import { buildLocalizedPath } from '@utils/functions'
import {
  Content,
  ImageContainer,
  Project,
  ProjectsContainer,
  Section
} from './style'

const Projects = () => {
  const data = useStaticQuery(query)
  const { formatMessage, locale } = useIntl()
  const nodes = data.allMarkdownRemark.edges
  return (
    <Section>
      <Container>
        <ProjectsContainer>
          {
            nodes
              .filter(n => n.node.fields.locale === locale)
              .map(n => {
                const image = `projects/${n.node.frontmatter.thumb}`
                return (
                  <Project key={n.node.fields.slug} to={`${buildLocalizedPath('/projects/', locale)}/${n.node.fields.slug}`}>
                    <ImageContainer>
                      <ResponsiveImage
                        alt={`${formatMessage({ id: 'Project' })} - ${n.node.frontmatter.name}`}
                        cover
                        filename={image}
                        style={{ maxHeight: 250 }}
                      />
                    </ImageContainer>
                    <Content>
                      <span>
                        {n.node.frontmatter.name}
                      </span>
                    </Content>
                  </Project>
                )
              })
          }
        </ProjectsContainer>
      </Container>
    </Section>
  )
}

const query = graphql`
  query AllProjects {
    allMarkdownRemark(
      filter: {
        fields: {
          sourceName: {
            eq: "projects"
          }
        }
      }
      sort: {
        fields: [frontmatter___projectOrder]
        order: DESC
      }
    ) {
      edges {
        node {
          fields {
            slug
            locale
          }
          frontmatter {
            name
            thumb
          }
        }
      }
    }
  }
`

export default Projects
