import { graphql, useStaticQuery } from 'gatsby'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { germanPages } from './pages'

export const useSiteData = () => {
  const siteQuery = graphql`
    query siteData {
      site {
        siteMetadata {
          title
          description
          siteUrl
          address
          addressLocality
          addressRegion
          postalCode
          addressCountry
          image
          linkedin
          phone
          phoneLink
          phone2
          phoneLink2
          email
        }
      }
    }
  `
  const data = useStaticQuery(siteQuery)
  return data.site.siteMetadata
}

export const trackGAEvent = (category, action, label, value) => {
  trackCustomEvent({
    category,
    action,
    label,
    value
  })
}

export const buildLocalizedPath = (path, locale = 'en') => {
  const pagePath = locale === 'en' ? path : germanPages[path]
  return `${locale === 'en' ? '' : locale}${pagePath}`
}
