import styled from 'styled-components'
import { red } from '@styles/theme'

export const Content = styled.div`
  max-width: 800px;
  padding: 50px 0;

  h2 {
    font-size: 26px;
    margin: 36px 0 16px;
  }

  dt {
    display: inline-block;
    font-weight: 500;
    line-height: 1.8;
    margin-right: 0;
  }

  dd {
    display: inline-block;
    margin-left: 4px;
  }

  a {
    color: ${red};
  }
`