import styled from 'styled-components'
import { Link } from 'gatsby'
import { darkGray } from '@styles/theme'
import { transition } from '@styles/utils'

export const ServiceContainer = styled.div`
  display: flex;
  margin-bottom: 96px;

  @media (max-width: 950px) {
    flex-direction: column;
    margin-bottom: 54px;
  }

  @media (max-width: 600px) {
    margin-bottom: 48px;
  }
`

export const ImageContainer = styled(Link)`
  flex: 4;

  @media (max-width: 950px) {
    order: -1;
    max-width: 500px;
    width: 100%;
  }

  > div {
    @media (max-width: 600px) {
      max-height: 250px;
    }
  }
`

export const TextContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 24px 32px; 
  order: ${props => props.alternative ? -1 : 1};
  margin-left: ${props => props.alternative ? '0' : '24px'};
  margin-right: ${props => props.alternative ? '24px' : '0'};

  @media (max-width: 950px) {
    padding: 24px 0;
    margin-left: 0;
  }

  @media (max-width: 600px) {
    padding-top: 8px;
  }

  h2 {
    font-weight: 500;
    font-size: 30px;

    @media (max-width: 950px) {
      font-size: 26px;
    }

    @media (max-width: 600px) {
      font-size: 22px;
    }
  }

  p {
    line-height: 1.5;
    margin-bottom: 32px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  a {
    text-transform: uppercase;
    border: 1px solid ${darkGray};
    color: inherit;
    text-decoration: none;
    padding: 12px 20px;
    letter-spacing: 0.5px;
    font-size: 13px;
    font-weight: 500;
    width: 140px;
    text-align: center;
    cursor: pointer;
    transition: ${transition('color,background')};

    &:hover {
      background: ${darkGray};
      color: #fff;
    }
  }
`