import React from 'react'
import { Fade } from 'react-reveal'
import { FormattedMessage } from 'react-intl'
import {
  Container,
  ResponsiveImage,
} from '@components'
import {
  Box,
  ImageContainer,
  FirstRow,
  LastRow,
  ServicesContainer,
} from './style'

const HomeServices = () => (
  <ServicesContainer>
    <Container>
      <FirstRow>
        <Box theme="light">
          <Fade bottom distance="50px">
            <h3>
              <FormattedMessage id="home.services.1.title" />
            </h3>
            <p>
              <FormattedMessage id="home.services.1.description" />
            </p>
            <a href="/usluge/izvodjenje-radova-protupozarne-zastite">
              <FormattedMessage id="More" />
            </a>
          </Fade>
        </Box>
        <ImageContainer>
          <ResponsiveImage
            filename="zgrada.jpg"
          />
        </ImageContainer>
      </FirstRow>
    <LastRow>
      <Box theme="dark">
        <Fade bottom distance="50px">
            <h3>
              <FormattedMessage id="home.services.2.title" />
            </h3>
            <p>
              <FormattedMessage id="home.services.2.description" />
            </p>
            <a href="/usluge/optimizacija-troskova-protupozarne-zastite">
              <FormattedMessage id="More" />
            </a>
          </Fade>
        </Box>
        <Box theme="red">
          <Fade bottom distance="50px">
            <h3>
              <FormattedMessage id="home.services.3.title" />
            </h3>
            <p>
              <FormattedMessage id="home.services.3.description" />
            </p>
            <a href="/usluge/edukacija">
              <FormattedMessage id="More" />
            </a>
          </Fade>
        </Box>
      </LastRow>
    </Container>
  </ServicesContainer>
)

export default HomeServices
