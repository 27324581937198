import React from 'react'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import { Container } from '@components'
import { Content, Section } from './style'
import { buildLocalizedPath } from '@utils/functions'

const Contact = ({ locale }) => (
  <Section>
    <Container small>
      <Content>
        <h4><FormattedMessage id="services.contact.title" /></h4>
        <p><FormattedMessage id="services.contact.text" /></p>
        <Link to={buildLocalizedPath('/contact/', locale)}><FormattedMessage id="services.contact.cta" /></Link>
      </Content>
    </Container>
  </Section>
)

export default Contact
