import React from 'react'
import { Fade } from 'react-reveal'
import { FormattedMessage } from 'react-intl'
import {
  Container,
  ResponsiveImage,
} from '@components'
import {
  Content,
  ImageContainer,
  Section,
  Text,
} from './style'

const Construction = () => {
  return (
    <Section>
      <Container>
        <Content>
          <Text>
            <Fade bottom distance="50px">
              <h3><FormattedMessage id="service.3.h.1" /></h3>
              <p><FormattedMessage id="service.3.p.1" /></p>
              <p><FormattedMessage id="service.3.p.2" /></p>

              <h3><FormattedMessage id="service.3.h.2" /></h3>
              <p><FormattedMessage id="service.3.p.3" /></p>
              <p><FormattedMessage id="service.3.p.4" /></p>
            </Fade>
          </Text>
          <ImageContainer>
            <ResponsiveImage filename="edu.jpg" alt="Edukacija - FSB d.o.o. protupožarna zaštita" />
          </ImageContainer>
        </Content>
      </Container>
    </Section>
  )
}

export default Construction
