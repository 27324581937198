import React from 'react'
import { Fade } from 'react-reveal'
import { FormattedMessage } from 'react-intl'
import { Container, ResponsiveImage } from '@components'
import {
  Content,
  ImageContainer,
  Section,
  TextContainer,
} from './style'

const Director = () => (
  <Section>
    <Container small>
      <Content>
        <ImageContainer>
          <ResponsiveImage
            alt="Stipe Barišić - Direktor FSB d.o.o. protupožarna zaštita"
            filename="barisic.jpg"
          />
          <span>
            dipl. Ing. Stipe Barišić
          </span>
        </ImageContainer>
        <TextContainer>
          <Fade bottom distance="50px">
            <h2><FormattedMessage id="about.director.title" /></h2>
            <p><FormattedMessage id="about.director.p.1" /></p>
            <p><FormattedMessage id="about.director.p.2" /></p>
            <p><FormattedMessage id="about.director.p.3" /></p>
            <p><FormattedMessage id="about.director.p.4" /></p>
          </Fade>
        </TextContainer>
      </Content>
    </Container>
  </Section>
)

export default Director
