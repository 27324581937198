import styled from 'styled-components'

export const Section = styled.section`
  background: #f4f4f4;
  padding: ${props => props.slim ? '80px 0 60px' : '100px 0 80px'};

  @media (max-width: 600px) {
    padding: 40px 0 20px;
  }
`
export const Content = styled.div`
  max-width: 700px;

  h1 {
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 8px;

    @media (max-width: 1100px) {
      font-size: 14px;
    }

    @media (max-width: 600px) {
      font-size: 13px;
    }
  }

  h2 {
    font-size: 32px;
    font-weight: 500;
    margin: 0 0 24px;
    line-height: 1.5;

    @media (max-width: 1100px) {
      font-size: 28px;
    }

    @media (max-width: 600px) {
      font-size: 26px;
    }
  }

  p {
    font-size: 15px;
    line-height: 1.7;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
`