import React from 'react'
import { Fade } from 'react-reveal'
import { FormattedMessage } from 'react-intl'
import {
  Container,
  ResponsiveImage,
} from '@components'
import {
  Content,
  Highlight,
  ImageContainer,
  List,
  Section,
  Text,
} from './style'

const Optimization = () => {
  return (
    <Section>
      <Container>
        <Content>
          <Text>
            <Fade bottom distance="50px">
              <Highlight>
                <FormattedMessage id="service.2.intro" />
              </Highlight>
            </Fade>
            <Fade bottom distance="50px">
              <p><FormattedMessage id="service.2.p.1" /></p>
              <p><FormattedMessage id="service.2.p.2" /></p>
              <List>
                <p><FormattedMessage id="service.2.p.3" /></p>
                <ul>
                  <li><FormattedMessage id="service.2.li.1" /></li>
                  <li><FormattedMessage id="service.2.li.2" /></li>
                  <li><FormattedMessage id="service.2.li.3" /></li>
                </ul>
              </List>
            </Fade>
          </Text>
          <ImageContainer>
            <ResponsiveImage filename="opti.jpg" alt="Optimizacija troškova protupožarne zaštite - FSB d.o.o. protupožarna zaštita" />
          </ImageContainer>
        </Content>
      </Container>
    </Section>
  )
}

export default Optimization
