export const transition = (props) => {
  let transitions = ''
  props.split(',').forEach((p,i) => {
    if (i !== 0) {
      transitions += ','
    }
    transitions += `${p} 150ms cubic-bezier(0.01, 0.7, 0.35, 0.94)`
  })
  return transitions
}
