import React from 'react'
import { Fade } from 'react-reveal'
import { FormattedMessage } from 'react-intl'
import { Container, ResponsiveImage } from '@components'
import {
  Content,
  Image,
  Section,
  Text,
} from './style'

const About = () => (
  <Section>
    <Container>
      <Fade bottom cascade distance="50px">
        <Content>
          <Text>
            <p><FormattedMessage id="about.law.1" /></p>
            <p><FormattedMessage id="about.law.2" /></p>
          </Text>
          <Image>
            <ResponsiveImage
              alt="Radnici FSB d.o.o. - protupožarna zaštita na gradilištu"
              filename="about.jpg"
            />
          </Image>
        </Content>
      </Fade>
    </Container>
  </Section>
)

export default About
