import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { FormattedMessage, useIntl } from 'react-intl'
import { Container } from '@components'
import { buildLocalizedPath } from '@utils/functions'
import Arrow from '@assets/images/arrow_white.svg'
import {
  Content,
  HeroContainer,
  StyledHero,
  StyledHeroContainer,
} from './style'

const Hero = () => {
  const data = useStaticQuery(query);
  const { locale } = useIntl()
  const image = data.desktop.childImageSharp.fluid
  return (
    <HeroContainer>
      <Container>
        <StyledHeroContainer>
          <StyledHero
            Tag="div"
            fluid={image}
            backgroundColor="#555"
          >
            <Content>
              <h1>
                <FormattedMessage id="home.hero.title" />
              </h1>
              <Link to={buildLocalizedPath('/services/', locale)}>
                <span>
                <FormattedMessage id="More information" />
                </span>
                <img src={Arrow} alt="" height={26} />
              </Link> 
            </Content>
          </StyledHero>
        </StyledHeroContainer>
      </Container>
    </HeroContainer>
  )
}

const query = graphql`
  query {
    desktop: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Hero
