import React from 'react'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { Container } from '@components'
import { useSiteData } from '@utils/functions'
import { FormattedMessage } from 'react-intl'
import {
  Contact,
  Content,
  Section,
} from './style'

const Contacts = () => {
  const site = useSiteData()
  return (
    <Section>
      <Container>
        <Content>
          <Contact>
            <span>
              <FormattedMessage id="contact.email" />
            </span>
            <div>
              <a href={`mailto:${site.email}`} onClick={() => trackCustomEvent({
                  category: 'contact',
                  action: 'email',
                  label: 'contact_page',
                })}>
                {site.email}
              </a>
            </div>
          </Contact>
          <Contact>
            <span>
              <FormattedMessage id="contact.phone" />
            </span>
            <div>
              <a href={`tel:${site.phoneLink}`}  onClick={() => trackCustomEvent({
                  category: 'contact',
                  action: 'phone',
                  label: 'contact_page',
                })}>
                {site.phone}
              </a>
              <br />
              <a href={`tel:${site.phoneLink2}`} onClick={() => trackCustomEvent({
                  category: 'contact',
                  action: 'phone',
                  label: 'contact_page',
                })}>
                {site.phone2}
              </a>
            </div>
          </Contact>
          <Contact>
            <span>
              <FormattedMessage id="contact.address.label" />
            </span>
            <div>
              <a href="https://www.google.com/maps/place/FSB+d.o.o./@45.811092,15.873422,17z/data=!3m1!4b1!4m5!3m4!1s0x4765d1a06eb53a81:0xf767673f33bcec1!8m2!3d45.811092!4d15.875616" onClick={() => trackCustomEvent({
                  category: 'contact',
                  action: 'address',
                  label: 'contact_page',
                })}>
                  <FormattedMessage id="contact.address.value.1" />
                  <br />
                  <FormattedMessage id="contact.address.value.2" />
              </a>
            </div>
          </Contact>
        </Content>
      </Container>
    </Section>
  )
}

export default Contacts
