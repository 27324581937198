import styled from 'styled-components'
import { red } from '@styles/theme'

export const Content = styled.div`
  max-width: 800px;
  padding: 50px 0;

  h2 {
    font-size: 22px;
    margin: 36px 0 16px;
  }

  h3 {
    font-size: 18px;
    margin: 12px 0 4px;
    font-weight: 500;
  }

  p {
    margin: 0 0 8px;
    line-height: 1.5;
  }
  ul {
    line-height: 1.5;
    margin: 16px 0;
  }

  a {
    color: ${red};
  }
`