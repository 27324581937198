import React from 'react'
import { Fade } from 'react-reveal'
import { FormattedMessage } from 'react-intl'
import {
  Container,
  ResponsiveImage,
} from '@components'
import {
  Content,
  Highlight,
  ImageContainer,
  Section,
  Text,
} from './style'

const Construction = () => {
  return (
    <Section>
      <Container>
        <Content>
          <Text>
            <Fade bottom distance="50px">
              <Highlight>
                <FormattedMessage id="service.1.intro" />
              </Highlight>
            </Fade>
            <Fade bottom distance="50px">
              <p><FormattedMessage id="service.1.p.1" /></p>
              <p><span><FormattedMessage id="service.1.p.2" /></span></p>

              <h3><FormattedMessage id="service.1.h.1" /></h3>
              <p><FormattedMessage id="service.1.p.3" /></p>
              <p><i><FormattedMessage id="service.1.p.4" /></i></p>

              <h3><FormattedMessage id="service.1.h.2" /></h3>
              <p><FormattedMessage id="service.1.p.5" /></p>
              <p><FormattedMessage id="service.1.p.6" /></p>
              <ul>
                <li><FormattedMessage id="service.1.li.1" /></li>
                <li><FormattedMessage id="service.1.li.2" /></li>
                <li><FormattedMessage id="service.1.li.3" /></li>
                <li><FormattedMessage id="service.1.li.4" /></li>
              </ul>
              <p><i><FormattedMessage id="service.1.p.7" /></i></p>

              <h3><FormattedMessage id="service.1.h.3" /></h3>
              <p><FormattedMessage id="service.1.p.8" /></p>
              <p><i><FormattedMessage id="service.1.p.9" /></i></p>

              <h3><FormattedMessage id="service.1.h.4" /></h3>
              <p><FormattedMessage id="service.1.p.10" /></p>
              <p><i><FormattedMessage id="service.1.p.11" /></i></p>

              <h3><FormattedMessage id="service.1.h.5" /></h3>
              <p><FormattedMessage id="service.1.p.12" /></p>
              <p><i><FormattedMessage id="service.1.p.13" /></i></p>

              <h3><FormattedMessage id="service.1.h.6" /></h3>
              <p><FormattedMessage id="service.1.p.14" /></p>
              <p><FormattedMessage id="service.1.p.15" /></p>
              <p><FormattedMessage id="service.1.p.16" /></p>
              <p><FormattedMessage id="service.1.p.17" /></p>
              <p><i><FormattedMessage id="service.1.p.18" /></i></p>
            </Fade>
          </Text>
          <ImageContainer>
            <ResponsiveImage filename="zgrada.jpg" alt="Optimizacija troškova protupožarne zaštite  - FSB d.o.o. protupožarna zaštita" />
          </ImageContainer>
        </Content>
      </Container>
    </Section>
  )
}

export default Construction
