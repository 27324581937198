import React from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = ({ isHomepage = false, meta }) => {
  const { site } = useStaticQuery(query)
  const siteData = site.siteMetadata
  const { formatMessage } = useIntl()

  const seo = {
    title: meta.title || siteData.title,
    description: meta.description || siteData.description,
    image: `${siteData.siteUrl}${meta.banner || siteData.image}`,
    url: `${siteData.siteUrl}${meta.path || ""}`,
  }

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data

  const schemaOrgWebPage = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "@id": siteData.siteUrl,
    name: `${siteData.title} - Protupožarna zaštita`,
    legalName: siteData.title,
    url: siteData.siteUrl,
    image: [
      `${siteData.siteUrl}${siteData.image}`
    ],
    logo: `${siteData.siteUrl}${siteData.logo}`,
    email: siteData.email,
    address: {
      "@type": "PostalAddress",
      streetAddress: siteData.siteUrl,
      addressLocality: siteData.addressLocality,
      addressRegion: siteData.addressRegion,
      addressCountry: siteData.addressCountry,
      postalCode: siteData.postalCode,
    },
    location: {
      "@type": "PostalAddress",
      streetAddress: siteData.siteUrl,
      addressLocality: siteData.addressLocality,
      addressRegion: siteData.addressRegion,
      addressCountry: siteData.addressCountry,
      postalCode: siteData.postalCode,
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": siteData.siteUrl,
    },
    // slogan: siteData.slogan,
    description: siteData.description,
    sameAs: [
      siteData.linkedin
    ]
  }

  return (
    <>
      <Helmet title={`${formatMessage({ id: seo.title })} - ${formatMessage({ id: 'site.title' })}`}>
        <html lang="hr" />
        <meta name="description" content={formatMessage({ id: seo.description })} />
        <meta name="copyright" content="FSB Ltd." />
        <meta property="og:type" content={meta.type || 'website'} />
        {/* <meta property="og:url" content={siteData.siteUrl} /> */}
        {/* <meta property="og:locale" content="hr_hr" /> */}
        <meta property="og:title" content={`${formatMessage({ id: seo.title })} - ${formatMessage({ id: 'site.title' })}`} />
        <meta property="og:description" content={formatMessage({ id: seo.description})} />
        <meta property="og:image" content={`${siteData.siteUrl}${meta.image || siteData.image}`} />
        <meta property="og:image:secure_url" content={`${siteData.siteUrl}${meta.image || siteData.image}`} />
        <meta property="og:email" content={siteData.email} />
        <meta property="og:phone_number" content={siteData.phoneLink} />

        {!isHomepage && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
      </Helmet>
    </>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        image
        logo
        title
        description
        linkedin
        phoneLink
        email
        slogan
      }
    }
  }
`
