import styled from 'styled-components'

export const Section = styled.section`
  padding: 24px 0 64px;
`

export const Content = styled.div`
  padding: 64px 24px;
  display: flex;
  justify-content: space-around;
  background: #444;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`

export const Figure = styled.div`
  color: #fff;

  > div {
    &:first-of-type {
      font-size: 14px;
      opacity: 0.7;

      @media(max-width: 900px) {
        font-size: 13px;
      }
    }

    &:last-of-type {
      font-size: 100px;
      font-weight: 500;

      @media(max-width: 900px) {
        font-size: 80px;
      }

      @media(max-width: 750px) {
        font-size: 60px;
      }

      @media(max-width: 600px) {
        margin-bottom: 24px;
        font-size: 80px;
      }
    }
  }
`