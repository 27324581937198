import styled from 'styled-components'
import { Link } from 'gatsby'

export const Section = styled.section`
  background: #f4f4f4;
  padding: 16px 0 8px;
`

export const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: 13px;
  color: #777;

  :hover {
    color: inherit;
    text-decoration: underline;
  }

  img {
    margin-right: 4px;
    opacity: 0.8;
    transform: rotate(180deg);
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    display: none;
  }

  i {
    font-style: normal;
    margin: 0 3px 0 3px;
    color: #999;
  }
`

export const MobileContent = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`