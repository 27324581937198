import styled from 'styled-components'

export const Section = styled.section`
  padding: 64px 0;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const Text = styled.div`
  flex: 4;
  max-width: 700px;

  > p {
    font-size: 17px;
    line-height: 1.5;
  }
`

export const Highlight = styled.p`
  margin: 16px 0 64px;
  font-size: 24px !important;
  line-height: 1.5;
`

export const List = styled.div`
  margin-top: 48px;
  font-size: 18px;

  > ul {
    > li {
      margin-bottom: 10px;
    }
  }
`

export const ImageContainer = styled.div`
  margin-left: 80px;
  flex: 3;

  @media (max-width: 800px) {
    order: -1;
    margin-left: 0;
    max-width: 600px;
    margin-bottom: 32px;
  }
`