import React from 'react'
import { Fade } from 'react-reveal'
import { FormattedMessage } from 'react-intl'
import {
  Container,
} from '@components'
import {
  Content,
  Section,
  Text,
} from './style'

const Testing = () => {
  return (
    <Section>
      <Container>
        <Content>
          <Text>
            <Fade bottom distance="50px">
              <p><FormattedMessage id="service.4.p.1" /></p>
              <ul>
                <li><FormattedMessage id="service.4.li.1" /></li>
                <li><FormattedMessage id="service.4.li.2" /></li>
              </ul>  
              <p><FormattedMessage id="service.4.p.2" /></p>
            </Fade>
          </Text>
        </Content>
      </Container>
    </Section>
  )
}

export default Testing
