import React from 'react'
import { Fade } from 'react-reveal'
import { FormattedMessage } from 'react-intl'
import { Container, ResponsiveImage } from '@components'
import { Column, ColumnsContainer, Image } from './style'

const Bullets = () => (
  <Container small>
    <Image>
      <ResponsiveImage
        alt="Certifikati kvalitete tvrtke FSB d.o.o. protupožarna zaštita"
        filename="certificates.png"
      />
    </Image>
    <ColumnsContainer>
      <Fade bottom distance="50px">
        <Column>
          <h2><FormattedMessage id="about.bullet.1.title" /></h2>
          <p><FormattedMessage id="about.bullet.1.p.1" /></p>
          <p><FormattedMessage id="about.bullet.1.p.2" /></p>
        </Column>
      </Fade>
      <Fade bottom distance="50px">
        <Column>
          <h2><FormattedMessage id="about.bullet.2.title" /></h2>
          <p><FormattedMessage id="about.bullet.2.p" /></p>
        </Column>
      </Fade>
    </ColumnsContainer>
  </Container>
)

export default Bullets
