import { createGlobalStyle } from 'styled-components'
import { bodyColor } from '@styles/theme'

export const GlobalStyles = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    font-family: 'IBM Plex Sans', Tahoma, Helvetica, Arial, sans-serif;
    color: ${bodyColor};
  }
`
