import styled from 'styled-components'
import { bodyColor, darkGray, lightGray, red } from '@styles/theme'
import { transition } from '@styles/utils'

const getBackground = (theme) => {
  switch (theme) {
    case 'red':
      return red;
    case 'dark':
      return darkGray;
    default:
      return lightGray;
  }
}

const getColor = (theme) => {
  switch (theme) {
    case 'light':
      return bodyColor;
    default:
      return 'rgba(255,255,255,1)';
  }
}

export const ServicesContainer = styled.section`
  padding: 50px 0;
`
export const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (max-width: 600px) {
    margin: 0 -15px;
  }

  & > div {
    &:first-of-type {
      flex: 5;
      margin-right: 24px;

      @media (max-width: 1200px) {
        flex: 3;
      }

      @media (max-width: 992px) {
        margin: 0;
      }

    }
    &:last-of-type {
      flex: 3;

      @media (max-width: 992px) {
        display: none;
      }
    }
  }
`

export const LastRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (max-width: 850px) {
    flex-direction: column;
  }

  @media (max-width: 600px) {
    margin: 0 -15px -60px;
  }

  & > div {
    &:first-of-type {
      flex: 1;
      margin-right: 24px;

      @media (max-width: 850px) {
        margin-bottom: 24px;
        margin-right: 0;
      }

      @media (max-width: 600px) {
        margin: 0;
      }
    }
    &:last-of-type {
      flex: 1;
    }
  }
`

export const Box = styled.div`
  padding: 64px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: ${props => getBackground(props.theme)};
  color: ${props => getColor(props.theme)};

  @media (max-width: 600px) {
    padding: 64px 15px;
  }

  h3 {
    font-size: 32px;
    font-weight: 500;
    margin: 0 0 24px;
    max-width: 450px;

    @media (max-width: 1000px) {
      font-size: 28px;
    }

    @media (max-width: 600px) {
      font-size: 24px;
    }
  }

  p {
    margin: 0;
    line-height: 1.5;
    max-width: 600px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  a {
    text-transform: uppercase;
    border: 1px solid ${props => getColor(props.theme)};
    color: inherit;
    text-decoration: none;
    padding: 8px 16px;
    letter-spacing: 0.5px;
    font-size: 13px;
    font-weight: 500;
    width: 100px;
    text-align: center;
    margin-top: 32px;
    cursor: pointer;
    transition: ${transition('color,background')};

    &:hover {
      background: ${props => getColor(props.theme)};
      color: ${props => props.theme === 'light' ?  '#fff' : darkGray};
    }
  }
`

export const ImageContainer = styled.div`

`