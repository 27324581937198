import styled from 'styled-components'
import { red } from '@styles/theme'

export const Content = styled.div`
  max-width: 800px;
  min-height: calc(30vh);
  padding: 50px 0;


  p {
    font-size: 17px;
    margin: 0 0 16px;
    line-height: 1.8;
  }

  a {
    color: ${red};
  }
`