import styled from 'styled-components'
import { darkGray, red } from '@styles/theme'
import { transition } from '@styles/utils'

export const Section = styled.section`
  margin: 0 0 100px;
  padding: 100px 50px;
  background: #f4f4f4;
`

export const Content = styled.div`
  max-width: 600px;

  h4 {
    font-size: 30px;
    font-size: 500;
    margin: 0 0 16px;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 40px;
  }

  a {
    text-transform: uppercase;
    border: 1px solid ${red};
    text-decoration: none;
    padding: 12px 20px;
    letter-spacing: 0.5px;
    font-size: 13px;
    font-weight: 500;
    width: 140px;
    text-align: center;
    margin-top: 32px;
    cursor: pointer;
    background: ${red};
    transition: ${transition('border,color,background')};
    color: #fff;
    
    &:hover {
      background: #fff;
      color: ${darkGray};
      border: 1px solid ${darkGray};
    }
  }
`
