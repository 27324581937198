import React from 'react'
import { Link } from 'gatsby'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { Container } from '@components'
import { FormattedMessage } from 'react-intl'
import {
  FooterBottom,
  FooterBottomContainer,
  FooterContainer,
  FooterContainerContent,
  Info,
  Menu,
  Menus,
} from './style'
import { useSiteData } from '@utils/functions'
import LogoSrc from '@assets/images/logo_white.svg'
import Linkedin from '@assets/images/linkedin.svg'
import { buildLocalizedPath } from '@utils/functions'

const Footer = ({ locale }) => {
  const site = useSiteData()

  return (
    <footer>
      <FooterContainer>
        <Container>
          <FooterContainerContent>
            <Info>
              <img src={LogoSrc} alt="FSB d.o.o." height={60} />
              <p>
                <a href="https://www.google.com/maps/place/FSB+d.o.o./@45.811092,15.873422,17z/data=!3m1!4b1!4m5!3m4!1s0x4765d1a06eb53a81:0xf767673f33bcec1!8m2!3d45.811092!4d15.875616"  onClick={() => trackCustomEvent({
                  category: 'contact',
                  action: 'address',
                  label: 'footer',
                })}>
                  <FormattedMessage id="contact.address.value.1" />
                  <br/>
                  <FormattedMessage id="contact.address.value.2" />
                </a>
              </p>
              <p>
                <a href={`mailto:${site.email}`} onClick={() => trackCustomEvent({
                  category: 'contact',
                  action: 'email',
                  label: 'footer'
                })}>
                  {site.email}
                </a>
              </p>
              <p>
                <a href={`tel:${site.phoneLink}`} onClick={() => trackCustomEvent({
                  category: 'contact',
                  action: 'phone',
                  label: 'footer',
                })}>
                  {site.phone}
                </a>
              </p>
              <p>
                <a href="https://www.linkedin.com/company/fsb-d.o.o./" rel="noopener noreferrer" target="_blank" onClick={() => trackCustomEvent({
                  category: 'social',
                  action: 'visit',
                  label: 'linkedin',
                })}>
                  <img src={Linkedin} alt="Linkedin" height="30px" />
                </a>
              </p> 
            </Info>
            <Menus>
              <Menu>
                <h5>
                  <Link to={buildLocalizedPath('/services/', locale)}>
                    <FormattedMessage id="header.link.services" />
                  </Link>
                </h5>
                <ul>
                  <li>
                    <Link to={buildLocalizedPath('/services/execution-of-fire-protection-works/', locale)}>
                      <FormattedMessage id="service.1.meta.title" />
                    </Link>
                  </li>
                  <li>
                    <Link to={buildLocalizedPath('/services/cost-optimization-of-fire-protection/', locale)}>
                      <FormattedMessage id="service.2.meta.title" />
                    </Link>
                  </li>
                  <li>
                    <Link to={buildLocalizedPath('/services/education/', locale)}>
                      <FormattedMessage id="service.3.meta.title" />
                    </Link>
                  </li>
                  <li>
                    <Link to={buildLocalizedPath('/services/laboratory-tests/', locale)}>
                      <FormattedMessage id="service.4.meta.title" />
                    </Link>
                  </li>
                </ul>
              </Menu>
            </Menus>
          </FooterContainerContent>
        </Container>
      </FooterContainer>
      <FooterBottomContainer>
        <Container>
          <FooterBottom>
            <div>
              <Link to={buildLocalizedPath('/privacy/', locale)}>
                <FormattedMessage id="footer.link.1" />
              </Link>
              <Link to={buildLocalizedPath('/impressum/', locale)}>
              <FormattedMessage id="footer.link.2" />
              </Link>
            </div>
            <span>
              &copy; <i><FormattedMessage id="footer.copy" /></i> 2010 - {new Date().getFullYear()}
            </span>
          </FooterBottom>
        </Container>
      </FooterBottomContainer>
    </footer>
  )
}

export default Footer