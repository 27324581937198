import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Container } from '@components'
import { Content, Section } from './style'

const Hero = ({
  description,
  title,
  slim,
  subtitle,
}) => {
  return (
    <Section slim={slim}>
      <Container>
        <Content>
          <h1>
            {title ? <FormattedMessage id={title} /> : ''}
          </h1>
          <h2>
            {subtitle ? <FormattedMessage id={subtitle} /> : ''}
          </h2>
          {
            description &&
            <p>
              <FormattedMessage id={description} />
            </p> 
          }
        </Content>
      </Container>
    </Section>
  )
}

export default Hero
