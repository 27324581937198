import styled from 'styled-components'
import { lightGray } from '@styles/theme'

export const Section = styled.section`
  background: ${lightGray};
  padding: 64px 0 64px;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`

export const Text = styled.div`
  flex: 4;
  font-size: 16px;
  line-height: 1.5;

  @media (max-width: 1100px) {
    max-width: 600px;
  }

  @media (max-width: 600px) {
    font-size: 15px;
  }
`

export const Image = styled.div`
  flex: 3;
  margin-left: 56px;

  @media (max-width: 1100px) {
    order: -1;
    max-width: 600px;
    width: 100%;
    margin-left: 0;
    margin-bottom: 32px;
  }

  @media (max-width: 600px) {
    margin-bottom: 16px;
  }
`