import React from 'react'
import { Container } from '@components'
import { FormattedMessage } from 'react-intl'
import { Content } from './style'

const Privacy = () => (
  <Container>
    <Content>
      <p><FormattedMessage id="privacy.intro.1" /></p>
      <p><FormattedMessage id="privacy.intro.2" /></p>

      <h2><FormattedMessage id="privacy.general.title" /></h2>
      <p><FormattedMessage id="privacy.general.p.1" /></p>
      <ul>
        <li><FormattedMessage id="privacy.general.list.item.1" /></li>
        <li><FormattedMessage id="privacy.general.list.item.2" /></li>
      </ul>
      <p><FormattedMessage id="privacy.general.p.2" /></p>
      <h3><FormattedMessage id="privacy.general.subtitle.1" /></h3>
      <p><FormattedMessage id="privacy.general.p.3" /></p>
      <h3><FormattedMessage id="privacy.general.subtitle.2" /></h3>
      <p><FormattedMessage id="privacy.general.p.4" /></p>

      <h2><FormattedMessage id="privacy.usage.title" /></h2>
      <p><FormattedMessage id="privacy.usage.p.1" /></p>
      <ul>
        <li><FormattedMessage id="privacy.usage.list.item.1" /></li>
        <li><FormattedMessage id="privacy.usage.list.item.2" /></li>
        <li><FormattedMessage id="privacy.usage.list.item.3" /></li>
        <li><FormattedMessage id="privacy.usage.list.item.4" /></li>
        <li><FormattedMessage id="privacy.usage.list.item.5" /></li>
      </ul>

      <h2><FormattedMessage id="privacy.content.title" /></h2>
      <p><FormattedMessage id="privacy.content.p.1" /></p>
      <p><FormattedMessage id="privacy.content.p.2" /></p>
      <ul>
        <li><FormattedMessage id="privacy.content.list.item.1" /></li>
        <li><FormattedMessage id="privacy.content.list.item.2" /></li>
        <li><FormattedMessage id="privacy.content.list.item.3" /></li>
        <li><FormattedMessage id="privacy.content.list.item.4" /></li>
        <li><FormattedMessage id="privacy.content.list.item.5" /></li>
        <li>
          <li><FormattedMessage id="privacy.content.list.item.6" /></li>
          <ul>
            <li><FormattedMessage id="privacy.content.list.item.6.1" /></li>
            <li><FormattedMessage id="privacy.content.list.item.6.2" /></li>
            <li><FormattedMessage id="privacy.content.list.item.6.3" /></li>
          </ul>
        </li>
      </ul>

      <h2><FormattedMessage id="privacy.secrecy.title" /></h2>
      <p><FormattedMessage id="privacy.secrecy.p.1" /></p>

      <h2><FormattedMessage id="privacy.analytics.title" /></h2>
      <p><FormattedMessage id="privacy.analytics.p.1" /></p>
      <p><FormattedMessage id="privacy.analytics.p.2" /></p>

      <h2><FormattedMessage id="privacy.cookies.title" /></h2>
      <p><FormattedMessage id="privacy.cookies.p.1" /></p>

      <h2><FormattedMessage id="privacy.security.title" /></h2>
      <p><FormattedMessage id="privacy.security.p.1" /></p>
      <p><FormattedMessage id="privacy.security.p.2" /></p>

      <h2><FormattedMessage id="privacy.control.title" /></h2>
      <p><FormattedMessage id="privacy.control.p.1" /></p>
      <p><FormattedMessage id="privacy.control.p.2" /></p>
      <p><FormattedMessage id="privacy.control.p.3" /></p>
      <p><FormattedMessage id="privacy.control.p.4" /></p>
      <p><FormattedMessage id="privacy.control.p.5" /></p>

      <h2><FormattedMessage id="privacy.links.title" /></h2>
      <p><FormattedMessage id="privacy.links.p.1" /></p>
      <p><FormattedMessage id="privacy.links.p.2" /></p>

      <h2><FormattedMessage id="privacy.changes.title" /></h2>
      <p><FormattedMessage id="privacy.changes.p.1" /></p>
    </Content>
  </Container>
)

export default Privacy
