import styled from 'styled-components'

export const Section = styled.section`
  padding: 64px 0;

  @media (max-width: 900px) {
    padding: 32px 0;
  }

  @media (max-width: 600px) {
    padding: 16px 0;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const Contact = styled.div`
  flex: 1 1 250px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 16px;

  @media (max-width: 900px) {
    flex: 1;
    text-align: center;
  }

  span {
    opacity: 0.5;
    font-size: 16px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  div {
    font-size: 24px;
    margin-top: 8px;

    @media (max-width: 600px) {
      font-size: 18px;
    }

    a {
      text-decoration: none;
      color: inherit;
      line-height: 1.5;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`
