import styled from 'styled-components'

export const StyledContainer = styled.div`
  max-width: ${props => props.small ? '1100px' : '1300px'};
  margin: 0 auto;
  padding: 0 50px;
  box-sizing: border-box;

  @media (max-width: 800px) {
    padding: 0 30px;
  }

  @media (max-width: 600px) {
    padding: 0 15px;
  }
`
