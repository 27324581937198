import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import { Container } from '@components'
import { getCookie, setCookie } from './utils'
import { C, Content } from "./style"


const CookiesNotification = () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (!getCookie('_consent')) {
      setVisible(true)
    }
  }, [setVisible])

  const handleClose = useCallback(() => {
    setCookie('_consent', 1, 365)
    setVisible(false)
  },[setVisible])

  if (!visible) {
    return null
  }

  return (
    <C>
      <Container>
        <Content>
          <p>
            <FormattedMessage id="cookies.p.1" />
            &nbsp;
            <Link to="/zastita-privatnosti"><FormattedMessage id="cookies.link.text" /></Link>.
            &nbsp;
            <FormattedMessage id="cookies.p.2" />
          </p>
          <button onClick={handleClose}>
            <FormattedMessage id="cookies.button.text" />
          </button>
        </Content>
      </Container>
    </C>
  )
}

export default CookiesNotification
