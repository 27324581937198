import styled from 'styled-components'
import { darkGray, gray } from '@styles/theme'

export const FooterContainer = styled.div`
  background: ${gray};
  padding: 80px 0;

  @media (max-width: 1000px) {
    padding: 54px 0;
  }

  @media (max-width: 600px) {
    padding: 32px 0;
  }
`
  
export const FooterContainerContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 15px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  & > div {
    // display: flex;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

  }
`

export const Info = styled.div`
  font-size: 14px;
  opacity: 0.8;

  @media (max-width: 1000px) {
    text-align: center;
    margin-bottom: 54px;
  }

  @media (max-width: 600px) {
    margin-bottom: 32px;
  }
`

export const Menu = styled.div`
  margin: 0 50px;

  @media (max-width: 1000px) {
    margin: 0 32px;
  }

  @media (max-width: 600px) {
    margin: 0 16px;
    text-align: center;
  }

  h5 {
    font-size 18px;
    font-weight: 500;
    margin: 0 0 16px;
    opacity: 0.9;

    @media (max-width: 600px) {
      margin-top: 16px;
    }

    a {
      
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 8px;

      a {
        line-height: 1.5;
        opacity: 0.7;

        @media (max-width: 600px) {
          font-size: 13px;
        }
      }
    }

  }
`

export const Menus = styled.div`
  display: flex;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`

export const FooterBottomContainer = styled.div`
  background: ${darkGray};
  `
  
  export const FooterBottom = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: #aaa;

  @media (max-width: 350px) {
    flex-direction: column;
    padding: 16px 0;
    height: auto;
  }

  a {
    text-decoration: none;
    color: inherit;
    margin-right: 16px;

    &:hover {
      text-decoration: underline;
    }
  }

  i {
    font-style: normal;

    @media (max-width: 600px) {
      display: none;
    }
  }

  span {
    @media (max-width: 350px) {
      margin-top: 8px;
    }
  }
`
