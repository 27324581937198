import { Link } from 'gatsby'
import styled from 'styled-components'
import { lightGray } from '@styles/theme'
import { transition } from '@styles/utils'

export const Section = styled.div`
  padding: 64px 0;
`

export const ProjectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Project = styled(Link)`
  background: ${lightGray};
  flex: 1 1 250px;
  max-width: calc(25% - 16px);
  position: relative;
  text-decoration: none;
  color: inherit;
  margin: 0 16px 32px 0;
  transition: ${transition('background,color')};

  @media (max-width: 1100px) {
    max-width: calc(33% - 16px);
  }

  @media (max-width: 900px) {
    max-width: calc(50% - 16px);
  }

  @media (max-width: 600px) {
    max-width: 100%;
    width: 100%;
    flex: none;
    margin-right: 0;
    margin-bottom: 16px;
  }

  :hover {
    background: #444;
    color: #fff;
  }
`

export const ImageContainer = styled.div`
  overflow: hidden;
`

export const Content = styled.div`
  padding: 10px 16px;

  @media (max-width: 600px) {
    padding: 10px 12px;
  }

  & > span {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }
`
